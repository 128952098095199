<template>
  <div class="test-dashboard-table">
    <v-data-table
      item-key="id"
      :headers="headers"
      :items="dashboardData"
      :page.sync="options.page"
      class="elevation-1"
      show-expand
      single-expand
      :expanded.sync="expanded"
      :loading="loading"
      :items-per-page="options.itemsPerPage"
      loading-text="Loading... Please wait"
      hide-default-footer
      @page-count="returnTotalPages()"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            label="Search"
            :append-outer-icon="'mdi-send'"
            clear-icon="mdi-close-circle"
            class="mx-4"
            @click:append-outer="searchUser"
            @keydown="searchUser"
            clearable
            @click:clear="clearSearchUser"
          ></v-text-field>
          <font-awesome-icon
            :icon="'info-circle'"
            :size="'3x'"
            class="py-3 purple--text icon-info"
            alt="information"
          />
          <tippy toSelector=".icon-info" placement="top">
            <span class="custom">
              To search a specific colum enter “column name: search string.
              For example, entering first:joe would search for “joe” in the
              First column</span
            >
          </tippy>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            @click="newUser"
          >
            New User
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="d-flex">
            <LineChartOCEAN
              :chart-data="item.chartTestDashboard"
            ></LineChartOCEAN>
            <QustionnareSelect
              :completeU1="
                item.chartTestDashboard[0].surname === item.lastName &&
                item.chartTestDashboard[0].name === item.name
              "
              :expanded="expanded"
              :item="item"
              @update-data="getDataTestDashboard"
            />
          </div>
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon @click="openDeleteModal(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="options.page"
        :disabled="loading"
        :length="options.totalPages"
        :total-visible="10"
        @input="pageChange"
      />
    </div>
  </div>
</template>

<script>
import QustionnareSelect from '@components/Dashboard/QuestionnareSelect.vue';
import LineChartOCEAN from '@components/Charts/LineChartOCEAN.vue';

export default {
  name: 'TestDashboardTable',
  components: {
    LineChartOCEAN,
    QustionnareSelect,
  },
  props: {
    dashboardData: {
      type: Array,
      default: () => [],
    },
    loading: Boolean,
    options: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    search: '',
    expanded: [],
    // selectRole: { label: 'Admin', value: 'ADMIN_USER' },
    headers: [
      // {
      //   text: 'Phone', value: 'phone', align: 'center', sortable: false,
      // },
      {
        text: 'Name', value: 'name', align: 'center', sortable: false,
      },
      // {
      //   text: 'Last', value: 'lastName', align: 'center', sortable: false,
      // },
      {
        text: 'Email', value: 'yourEmail', align: 'center', sortable: false,
      },
      {
        text: 'Role', value: 'roles', align: 'center', sortable: false,
      },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
  }),
  methods: {
    getSearchOptions() {
      const searchTestDashboard = {};
      if (this.search) {
        const splitSearch = this.search.split(/;/g);

        if (splitSearch.length && splitSearch[0].indexOf(':') !== -1) {
          splitSearch.forEach((x) => {
            const trimSearch = x.trim();
            const getColumn = this.headers.find(y => y.text.toLowerCase() === trimSearch.substring(0, trimSearch.indexOf(':')));
            if (getColumn) {
              const getTextColumn = getColumn.text.toLowerCase();
              searchTestDashboard[getTextColumn] = trimSearch.substring(trimSearch.indexOf(':') + 1, trimSearch.length).trim();
            }
          });
        } else {
          searchTestDashboard.findByAllFields = this.search;
        }
        searchTestDashboard.withSearch = true;
      } else {
        searchTestDashboard.withSearch = false;
      }
      return searchTestDashboard;
    },
    pageChange(currentPage) {
      this.getDataTestDashboard(currentPage);
    },
    returnTotalPages() {
      return this.totalPages;
    },
    searchUser(e) {
      if (e.keyCode === 13 || e.button === 0) {
        this.getDataTestDashboard(1);
      }
    },
    clearSearchUser() {
      this.search = '';
      this.getDataTestDashboard();
    },
    getDataTestDashboard(newPage) {
      this.$emit('getDataTestDashboard', {
        page: newPage || this.options.page,
        searchTestDashboard: this.getSearchOptions(),
      });
    },
    openDeleteModal(item) {
      this.$emit('openDeleteModal', item);
    },
    newUser() {
      this.$emit('openEditModal');
    },
    editItem(item) {
      this.$emit('openEditModal', item);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.test-dashboard-table {
  .v-data-table-header  {
    background-color: $tableColor1;
  }
  td, th span {
    font-family: Montserrat, sans-serif;
  }
  th span {
    font-size: 15px;
  }
  tbody tr:nth-child(even) {
    background-color: $tableColor1;
  }
  tbody tr:nth-child(odd) {
    background-color: $tableColor2;
  }
  .v-pagination__item--active {
    background-color: $bgCardColor1 !important;
    border-color: $bgCardColor1 !important;
  }
  .col {
    display: flex;
    width: 400px;
    flex-wrap: wrap;
  }
  .v-input__control .v-input__slot{
    margin-bottom: -10px;
  }
}
</style>
