<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-card-title class="headline">
        Are you sure you want to delete this user?</v-card-title
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="close"
          :disabled="loading"
        >
          Cancel</v-btn
        >
        <v-btn
          color="blue darken-1"
          text
          @click="deleteUser"
          :disabled="loading"
        >
          OK</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      userId: null,
    };
  },
  props: {
    loading: {
      type: Boolean,
    },
  },
  methods: {
    deleteUser() {
      this.$emit('deleteUser', this.userId);
    },
    close() {
      this.dialog = false;
    },
    open(id) {
      this.userId = id;
      this.dialog = true;
    },
  },
};
</script>
