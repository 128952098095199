<template>
  <div class="line-chart-ocean">
    <div class="text-center mt-2">
      <h4>Consistency Score</h4>
    </div>
    <div class="chart-wrapper">
      <VueECharts
        :option="{...getChartData, series}"
        autoresize
        ref="chart"
      />
    </div>
  </div>
</template>

<script>
import VueECharts from 'vue-echarts';

export default {
  name: 'LineChartOCEAN',
  components: {
    VueECharts,
  },
  props: {
    chartData: {
      type: Array,
    },
  },
  beforeUpdate() {
    this.updateChart();
  },
  data: () => ({
    getChartData: {
      legend: {
        show: true,
        type: 'scroll',
        right: '0',
        orient: 'vertical',
      },
      xAxis: {
        type: 'category',
        data: ['O', 'C', 'E', 'A', 'N'],
      },
      yAxis: {
        min: '0',
        max: '6',
        type: 'value',
      },
    },
  }),
  computed: {
    series() {
      if (!this.chartData) {
        return [];
      }
      const results = [];
      const colors = ['brown', 'orange', 'yellow', 'green', 'blue'];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.chartData.length; i++) {
        if (this.chartData[0].name === this.chartData[i].name
          && this.chartData[0].name !== 'Average') {
          results.push({
            ...this.chartData[i],
            name: 'ME',
            surname: '',
            itemStyle: {
              color: '#a111ff',
            },
          });
        } else if (this.chartData[i].name === 'Average') {
          results.push({
            ...this.chartData[i],
            itemStyle: {
              color: '#ff5151',
            },
          });
        } else {
          results.push({
            ...this.chartData[i],
            itemStyle: {
              color: colors[this.random(1, colors.length)],
            },
          });
        }
      }
      return results.map((x) => {
        const result = x.result.split(/(?=[-+])/);
        return {
          type: 'line',
          name: `${x.name} ${x.surname ? x.surname : ''}`,
          data: result,
          color: x.itemStyle.color,
        };
      });
    },
  },
  methods: {
    updateChart() {
      this.$refs.chart.clear();
    },
    random(min, max) {
      return min + Math.random() * (max - min);
    },
  },
};
</script>

<style lang="scss" scoped>
  .line-chart-ocean {
    width: 60%;
    height: 350px;
    .chart-wrapper {
      height: calc(100% - 28px);
      padding-bottom: 16px;
    }
  }
</style>
